$onpage-index: 999;

.onPageNavigationWrapper {
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  opacity: 1;
  transition: all 0.2s ease-in-out;
  background-color: #fff;
  z-index: $onpage-index;
  overflow: hidden;
  @media print,
  screen and (min-width: 40em) {
    transform: translate(0,55px);
  }
  @media print,
  screen and (min-width: 64em) {
    transform: translate(0,0);
  }

  body[class='no-scroll'] & {
    z-index: $onpage-index - 20;
  }

  body[class*="opened-menu"] & {
    z-index: $onpage-index - 20;
  }

  &.scrolling {
    position: fixed;
    z-index: $onpage-index;
    transform: translate(0,6vh);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

    @media print,
    screen and (min-width: 64em) {
      transform: translate(0,0);
      z-index: $onpage-index + 20;
    }
  }

  &.hidden {
    opacity: 0;
    transform: translate(0,-100px);
    @media print,
    screen and (min-width: 64em) {
      opacity: 1;
      transform: translate(0,0);
    }
  }

  @media print,
  screen and (min-width: 64em) {
    height: 10vh;
    top: 0;
    flex-wrap: nowrap;
  }
}

.logoCell {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 5vw 1rem;
  margin-left: 2vw;
  transition: padding .3s cubic-bezier(.455, .03, .515, .955);

  @media print,
  screen and (min-width: 64em) {
    //flex: 1;
    width: auto;
    justify-content: center;
    padding: 0;
  }

  &.scrolling {

    @media print,
    screen and (min-width: 64em) {
      padding: 0;
    }
  }
}

.logoCompany {
  position: relative;
  left: 0;
  padding: 0;
  max-width: 40vw;
  max-height: 80%;
  transform: scale(1);
  transform-origin: center left;
  transform-style: preserve-3d;
  transition: all .3s cubic-bezier(.455, .03, .515, .955);

  @media print,
  screen and (min-width: 40em) {
    max-width: 25vw;
  }

  @media print,
  screen and (min-width: 64em) {
    padding: 0;
    max-width: 12rem;
    transform-origin: right bottom;
  }

  @media print,
  screen and (min-width: 85em) {
    left: 0;
  }

  &.scrolling {
    transform: scale(1);

    @media print,
    screen and (min-width: 40em) {
      transform: scale(1);
    }

    @media print,
    screen and (min-width: 64em) {
      max-width: 10rem;
      transform: scale(0.8);
      transform-origin: center;
    }
  }
}

.ltoLink {
  display: none;
  opacity: 0;
  padding: 0;

  .ltoLogo {
    width: 0;
  }

  @media print,
  screen and (min-width: 64em) {
    display: block;
    position: static;
    border-right: 1px solid #000;
  }

  @media print,
  screen and (min-width: 85em) {
    padding: 0 1rem 0 2rem;
  }

  &.scrolling {
    opacity: 1;

    @media print,
    screen and (min-width: 64em) {
      padding: 0 2rem 0 1.5vw;
    }

    @media print,
    screen and (min-width: 85em) {
      padding: 0 1rem 0 2rem;
    }

    .ltoLogo {
      width: inherit;
    }
  }
}

.ltoLogo {
  max-width: 2.5rem;
  height: auto;
}


// burger button
.mobileNavButton {
  justify-content: center;
  align-items: center;
  display: flex;
  text-decoration: none;

  @media print,
  screen and (min-width: 64em) {
    display: none;
  }

  .mobileNavButtonText {
    color: #0a0a0a;
    text-decoration: none;
  }

  &:hover {
    text-decoration: none;
  }

  svg {
    transform: rotate(0deg);
    transform-style: preserve-3d;
    transition: transform .3s cubic-bezier(.455, .03, .515, .955);
  }

  &.show {
    svg {
      transform: rotate(45deg);
    }
  }
}

.breadcrumb {
  border-bottom: 1px solid #f3f5f7;
}

.burger {
  background-color: #df1051;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  width: fit-content;
  padding: 0 1.5vw;
  color: #fefefe;
  height: 0;
  visibility: hidden;
  transition: height .3s ease-in-out;

  @media print,
  screen and (max-width: 64em) {
    display: none;
  }

  .scrolling & {
    visibility: visible;
    height: 100%;
    max-height: 500px;
  }

  &:hover {
    color: #fefefe;
    text-decoration: none;
  }
}