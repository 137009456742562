.OnPageNavigation {
  width: inherit;
  flex: 2;
  height: 0;
  opacity: 0;
  visibility: collapse;
  transition: all .3s cubic-bezier(.455, .03, .515, .955);
  @media print, screen and (min-width: 64em) {
    //width: calc(83.33333% - 5vw);
    height: 100%;
    opacity: 1;
    visibility: visible;
  }
  @media print, screen and (min-width: 85em) {
    //width: 83.33333%;
  }

  &.show {
    height: 100%;
    opacity: 1;
    visibility: visible;
  }
}

.navigation {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 0 0 0 6vw;
  margin: 0;
  height: 100%;
  font-weight: 500;
  list-style-type: none;
  background-color: #fff;
  overflow: hidden;
  @media print, screen and (min-width: 64em) {
    padding: 0;
    margin: 0;
    align-items: center;
    flex-direction: row;
    max-height: none;
  }
  @media print, screen and (min-width: 85em) {
    max-width: 95%;
    margin: 0 auto;
  }

  .show & {
    /*! purgecss ignore current */
    border-bottom: 3px solid #f3f5f7;
  }
}

.navigationItem {
  display: inline-block;
  margin: 0 0 .5rem 0;
  
  @media print, screen and (min-width: 64em) {
    margin: 0;
  }

  a {
    display: inline-block;
    color: #000;
    font-size: 1.25rem;
    hyphens: auto;
    cursor: pointer;

    @media print, screen and (min-width: 64em) {
      font-size: 1rem;
      margin: 0 1.25rem 0 0;
    }
  }

}