.headerImageWrapper {
    position: relative;
    width: 100%;
    //height: 30vh;
    margin: 0;
    padding: 0 2.5vw;

    .overlay {
        left: 2.5vw;
        right: 2.5vw;
    }

    @media print,
    screen and (min-width: 40em) {
        //height: 40vh;
        padding: 0 2vw;

        .overlay {
            left: 2vw;
            right: 2vw;
        }
    }

    span {
        width: 100%;
    }
}

.header {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 10;
    color: #fff;
}

.headerImage {
    width: 100%;
    //height: 100%;
    //object-fit: cover;
}